import React, { useEffect, useRef, useState } from 'react';

import Header from '../component/Header'

const Dasboard = function (props) {

    const [ProfilePopupShow, setProfilePopupShow] = useState(false)
    const [NotificationPopupShow, setNotificationPopupShow] = useState(false)

    const profileDropDownDivRef = useRef(null);
    const notificationDropDownDivRef = useRef(null);

    const handleClickOutside = function(event) {
        if (profileDropDownDivRef.current && !profileDropDownDivRef.current.contains(event.target)) {
            setProfilePopupShow(false);
        }

        if (notificationDropDownDivRef.current && !notificationDropDownDivRef.current.contains(event.target)) {
            setNotificationPopupShow(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
        }
    }, [])

    useEffect(() => {
        initialRedirection()
    }, [])
    
    const initialRedirection = () => {
        let type = localStorage.getItem('type') || null
        if(type && type == "Owner") {
            window.location.href = "/project-logs"
        }
    }

    return (
        <div>
            <section id="contents">
                <Header/>
                <section className="statistics">
                    <div className="container-fluid">
                        <div className="row">
                            {
                                (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account' || localStorage.getItem('type') == 'Member')?(
                                    <div className="col-md-4">
                                        <div className="box">
                                            <a href="/project-logs">
                                                <div className="info">
                                                    <h3>Project Performance</h3>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ):''
                            }
                            {
                                (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'HR' ) ? (
                                    <div className="col-md-4">
                                        <div className="box">
                                            <a href="/performance">
                                                <div className="info">
                                                    <h3>Resource Performance</h3>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ) : ''
                            }
                            
                            {
                                ( localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') != 'HR') ? (
                                    <div className="col-md-4">
                                        <div className="box">
                                            <a href="/wbs-upload">
                                                <div className="info">
                                                    <h3>WBS Upload</h3>
                                                    <i className="fa fa-star" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                ):''
                            }
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}
export default Dasboard;