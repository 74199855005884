
import { CSVReader } from 'react-papaparse';
import Header from '../component/Header';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import $ from 'jquery';
import React from 'react';
import { Link } from 'react-router-dom';
//import { Link } from 'react-router'
//import getBaseUrl  from "../component/getBaseUrl";
class Wbsupload extends React.Component{

    componentDidMount=()=>{
        console.log(process.env, "ksdhakjsd")
        //this._baseUrl = getBaseUrl(this.props)
    }
    uploadTasks=(postData)=>{
        // console.log('jsonData', JSON.stringify(postData));
        return new Promise((resolve,reject)=>{
            fetch(global.config.api.remotePath + 'tasks/tasks_upload', {
                method: 'post',
                body: JSON.stringify(postData),
                headers: {
                    'content-type': 'application/json'
                }
            })
            .then((res) => res.json())
            .then(data => {
                if (data) {
                    resolve({ success: true, data: data })
                }
                else {
                    resolve({ success: false, data: [] })
                }
            })
            .catch(err => {           
                console.log("err",err);                    
                resolve({ success: false, err: err })
            })
        });
    }
    handleOnDrop = async (data) => {
        //console.log('data',data);
        if(data.length>0){
            let dataArray = [];
            // let i=0;    
            for (let i = 0; i < data.length; i++) {
                let element = data[i]
                // if (i > 0) {
                //     //console.log('dataArray', element.data);
                //     var temA = {};
                //     temA['project_id'] = element.data[1];
                //     temA['project_name'] = element.data[2];
                //     temA['task_name'] = element.data[4];
                //     temA['task_start_on_date'] = element.data[5];
                //     temA['task_due_on_date'] = element.data[6];
                //     temA['estimates_time'] = element.data[7];
                //     temA['assignee_email'] = element.data[8];
                //     temA['pl_email'] = element.data[9];
                //     //console.log("temA", temA)
                //     await dataArray.push(temA);
                // }

                if (i > 0) {
                    //console.log('dataArray', element.data);
                    var temA = {};
                    // temA['project_id'] = element.data[1];
                    temA['project_name'] = element.data[0];
                    temA['task_name'] = element.data[2];
                    temA['task_start_on_date'] = element.data[3];
                    temA['task_due_on_date'] = element.data[4];
                    temA['estimates_time'] = element.data[5];
                    temA['assignee_email'] = element.data[6];
                    temA['pl_email'] = localStorage.getItem('email') ?? "";
            
                    //console.log("temA", temA)
                    await dataArray.push(temA);
                }
            }

            await this.uploadTasks(dataArray)
            .then(async data=>{                          
                window.location.reload();
                if(data.data.status == 0) {
                    await alert(data.data.error || "Something went wrong")
                }
                if(data.success && data.data.data.success && data.data.data.error){                    
                    await alert(`Upload successfull, data inserted: ${data.data.data.success.length}, not inserted: ${data.data.data.error.length}`);                 
                }       
            });

        }
        
    };

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
    };

    handleOnRemoveFile = (data) => {
        console.log('---------------------------');
        console.log(data);
        console.log('---------------------------');
    };
    render=()=>{
        return(
            <div>
                <section id="contents">
                    <Header></Header>
                    <section className="statistics project-tab">
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account' || localStorage.getItem('type') == 'Member') ? (
                                        <div className="col-md-2 width-auto2">
                                            <div className="box">
                                                <a href="/project-logs">
                                                    <div className="info">
                                                        <h3>Project Performance</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'HR') ? (
                                        <div className="col-md-2 width-auto2">
                                            <div className="box">
                                                <a href="/performance">
                                                    <div className="info">
                                                        <h3>Resource Performance</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'HR') ? (
                                        <div className="col-md-2 width-auto2 active">
                                            <div className="box" style={{ backgroundColor: '#42ca37' }}>
                                                <a href="/wbs-upload">
                                                    <div className="info">
                                                        <h3>WBS Upload</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }

                            </div>
                        </div>
                    </section>
                    <section className="statistics">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box" style={{'min-height':'200px'}}>
                                        <div className="col-md-12">
                                            <div className="section2">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="upload_part">
                                                            
                                                            <CSVReader
                                                                onDrop={this.handleOnDrop}
                                                                onError={this.handleOnError}
                                                                addRemoveButton
                                                                onRemoveFile={this.handleOnRemoveFile}
                                                            >
                                                                <span>Drop CSV file here or click to upload.</span>
                                                            </CSVReader>
                                                        </div>
                                                        <div className="download_sample_part text-right">
                                                            {/* <button onClick={() => window.open('http://performance.maxmobility.in/sample-wbs.csv')}>Download sample csv</button> */}
                                                            {/* <a href={'http://localhost:3000/public/sample-wbs.csv'} download={'sample-wbs.csv'}>Download sample file</a> */}
                                                            <a href='/sample-wbs.csv' className='btn btn-primary' style={{marginTop:'5px'}} download={'sample-wbs.csv'}>Download sample file</a>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        );
        
    }
}
export default Wbsupload;