import React, { Component } from "react";
import CryptoJs from 'crypto-js';
import ReactDOM from 'react-dom';
import { Redirect } from "react-router-dom";
import "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ChangePassword extends Component{
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            userEmail: '',
            password:'',
            confirmPassword:''
        }
        
    }
    codeCheckingApi = (filterData)=>{
        return new Promise((resolve,reject)=>{
            fetch(global.config.api.remotePath+'user/check-change-password-code',{
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res)=>res.json())
            .then(data=>{
                if(data){
                    resolve({success:true,data:data})
                } else {
                    resolve({ success: false, data: [] })
                }
            }).catch(err => {
                resolve({ success: false, err: err })
            })
        });
    }

    changePasswordApi = (filterData) => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + 'user/change-password-submit', {
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    if (data) {
                        resolve({ success: true, data: data })
                    } else {
                        resolve({ success: false, data: [] })
                    }
                }).catch(err => {
                    resolve({ success: false, err: err })
                })
        });
    }

    componentDidMount=async()=>{
        var searchData=[];
        var code = this.props.match.params.code
        searchData.push({ code: code});
        await this.codeCheckingApi(searchData).then(data=>{
            //console.log('apidata',data); return;
            if(data.data.status==0){
                toast.error("Wrong code enter.");
                window.location.href='/admin-login';
                
            } else{
                if(data.data){
                    //console.log('api_data',data.data.data[0].user_email);
                    this.setState({ userEmail: data.data.data[0].user_email });
                } else{
                    toast.error("Oops! Something went wrong");
                    window.location.href = '/admin-login';
                    
                }
                
            }
        });
        console.log("queryString", this.props.match.params.code);

    }
    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
    }
    handleSubmit=async()=>{
        var password=this.state.password;
        var confirmPassword=this.state.confirmPassword;
        if(password!==confirmPassword){
            toast.error("Password and confirm password not same!");
            
        } else{
            var submitData=[];
            //const secretKey = '_zefdsuh123';
            let pwd = CryptoJs.AES.encrypt(this.state.password, global.config.config.secretKey_pwd).toString();
            submitData.push({ 'userEmail': this.state.userEmail, 'password': password,'type':1});
            //console.log(submitData); return;
            await this.changePasswordApi(submitData).then(data => {
                console.log('changeData', data.data);
                if (data.data.status == 1) {
                    toast.error("Password change successfully. Please login again with your new password.");
                    window.location.href = '/admin-login';

                } else {
                    toast.error("Oops! Something went wrong");
                }
            });

        }
    }
    render(){
        return(
            <div>
                <section>
                    <div class="wrapper">
                        <ToastContainer />
                        <div class="form-signin">
                            <h2 class="form-signin-heading">Change password</h2>
                            <div class="form-group">
                                <label for="email">Password*</label>
                                <input type="password" class="form-control" name="password" placeholder="Password" required="" autofocus="" value={this.state.password} onChange={(event) => this.setState({ password: event.target.value })} />
                            </div>
                            <div class="form-group">
                                <label for="password">Confirm Passord*</label>
                                <input type="password" class="form-control" name="confirmPassword" required="" autofocus="" value={this.state.confirmPassword} onChange={(event) => this.setState({ confirmPassword: event.target.value })} />
                            </div>
                            <button onClick={() => this.handleSubmit()} class="btn log btn-block">Change</button>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default ChangePassword;