import React, { useEffect, useRef, useState } from 'react'

import $ from 'jquery'

export default function Header() {

    const [ProfilePopupShow, setProfilePopupShow] = useState(false)
    const [NotificationPopupShow, setNotificationPopupShow] = useState(false)

    const profileDropDownDivRef = useRef(null);
    const notificationDropDownDivRef = useRef(null);

    const handleClickOutside = function (event) {
        if (profileDropDownDivRef.current && !profileDropDownDivRef.current.contains(event.target)) {
            setProfilePopupShow(false);
        }

        if (notificationDropDownDivRef.current && !notificationDropDownDivRef.current.contains(event.target)) {
            setNotificationPopupShow(false);
        }
    }

    // Same as ComponentDidMount
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        $(function () {

            'use strict';

            var aside = $('.side-nav'),
                showAsideBtn = $('.show-side-btn'),
                contents = $('#contents'),
                _window = $(window)

            showAsideBtn.on("click", function () {
                $("#" + $(this).data('show')).toggleClass('show-side-nav');
                contents.toggleClass('margin');
            });

            if (_window.width() <= 767) {
                aside.addClass('show-side-nav');
            }

            _window.on('resize', function () {
                if ($(window).width() > 767) {
                    aside.removeClass('show-side-nav');
                }
            });

            // dropdown menu in the side nav
            var slideNavDropdown = $('.side-nav-dropdown');

            $('.side-nav .categories li').on('click', function () {

                var $this = $(this)

                $this.toggleClass('opend').siblings().removeClass('opend');

                if ($this.hasClass('opend')) {
                    $this.find('.side-nav-dropdown').slideToggle('fast');
                    $this.siblings().find('.side-nav-dropdown').slideUp('fast');
                } else {
                    $this.find('.side-nav-dropdown').slideUp('fast');
                }
            });

            $('.side-nav .close-aside').on('click', function () {
                $('#' + $(this).data('close')).addClass('show-side-nav');
                contents.removeClass('margin');
            });

        });

        return () => {
        }
    }, [])

    return (
        <nav className="navbar navbar-default">
            <div className="container-fluid">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <i className="fa fa-align-right"></i>
                    </button>
                    <a className="navbar-brand" href="/admin-dashboard"><span className="main-color">Dashboard</span></a>
                </div>
                <div className="collapse navbar-collapse navbar-right" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav">
                        <li className="dropdown">
                            <div className="dropdown" ref={profileDropDownDivRef}>
                                <button className="btn  profile_drop" type="button" data-toggle="dropdown" onClick={() => {
                                    setProfilePopupShow(!ProfilePopupShow)
                                    setNotificationPopupShow(false)
                                }}>{localStorage.getItem("firstName")}</button>
                                {
                                    (ProfilePopupShow) ? (
                                        <ul className="dropdown-menu profile_drop_div" style={{ display: 'block', left: '-20px' }}>
                                            <li><a href="/admin-logout">Logout</a></li>
                                            {/* <li><a href="#">CSS</a></li>
                                            <li><a href="#">JavaScript</a></li>
                                            <li className="divider"></li>
                                            <li><a href="#">About Us</a></li> */}
                                        </ul>
                                    ) : ""
                                }
                            </div>
                        </li>

                       {/*  <li ref={notificationDropDownDivRef}>
                            <a href="#" className="notification_btn" onClick={() => {
                                setNotificationPopupShow(!NotificationPopupShow); setProfilePopupShow(false);
                            }}><i className="fa fa-bell-o"></i><span>98</span></a>
                            {
                                (NotificationPopupShow) ? (
                                    <ul className="dropdown-menu notification_btn_div" style={{ display: 'block', left: '-20px' }}>
                                        <li><a href="#">HTML</a></li>
                                        <li><a href="#">CSS</a></li>
                                        <li><a href="#">JavaScript</a></li>
                                        <li className="divider"></li>
                                        <li><a href="#">About Us</a></li>
                                    </ul>
                                ) : ""
                            }

                        </li> */}
                        <li><a href="#"><i data-show="show-side-navigation1" className="fa fa-bars show-side-btn"></i></a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
