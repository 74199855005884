import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Header from '../component/Header';
import Moment from 'react-moment';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import $ from 'jquery';
import DataTable from 'react-data-table-component';

class ProjectLogs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            fromDate: '',
            toDate: '',
            leaderDrops: [],
            page: 1,
            limit: 100,
            prev: 1,
            next: 1,
            isLoding: true,
            totalBudget: 0,
            totalEstMan: 0,
            totalActualMan: 0,
            totalActualdev: 0,
            totalAssiTicket: 0,
            totalCloseTicket: 0,
            totalPendingTicket: 0,
            totalPendingTicketMan: 0,
            totalActualBudget: 0,
            leaderId: 0,
            status: 0,
            budgetShow: true,
            budgetdeviation: 0,
            budgetdeviationCount: 0,
            csvData: [],
            csvDataDetails: [],
            period: 5,
            dateFilterShow: false,
            projectType: 0,
            so_rev: false,
            budget_rev: false,
            actual_rev: false,
            dbudgetdeviationCount: 0,
            dtotalActualMan: 0,
            dtotalActualdev: 0,
            dtotalAssiTicket: 0,
            dtotalCloseTicket: 0,
            dtotalPendingTicket: 0,
            dtotalPendingTicketMan: 0,
            dotalActualBudget: 0,
            dbudgetdeviation: 0,
            dtotalBudget: 0,
            dtotalEstMan: 0,
            dtotalActualBudget: 0,
            totalPeriodEstMan: 0,
        }
    }

    getLeaders = () => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + 'user/leader', {
                method: 'get',
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    if (data) {
                        resolve({ success: true, data: data })
                    }
                    else {
                        resolve({ success: false, data: [] })
                    }
                })
                .catch(err => {
                    resolve({ success: false, err: err })
                })
        })
    }

    getProjects = () => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + 'projects/project_logs', {
                method: 'get',
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    if (data) {
                        resolve({ success: true, data: data })
                    }
                    else {
                        resolve({ success: false, data: [] })
                    }
                })
                .catch(err => {
                    resolve({ success: false, err: err })
                })
        })
    }

    getProjectsFilter = (filterData) => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + 'projects/project_logs_filter', {
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    if (data) {
                        resolve({ success: true, data: data })
                    }
                    else {
                        resolve({ success: false, data: [] })
                    }
                })
                .catch(err => {
                    resolve({ success: false, err: err })
                })
        })
    }

    componentDidMount = async () => {
        this.setState({ isLoding: true })
        this.getLeaders().then(data => {
            //console.log("projects", data.data.data)
            if (data.data) {
                this.setState({ leaderDrops: data.data.data });
            }
        });
        //var startDateOfTheYear = new Date(moment().startOf('year').format('YYYY-MM-DD'));
        //var endDateOfTheYear = new Date(moment().format('YYYY-MM-DD'));
        if (moment().quarter() === 2) {
            var startDateOfTheYear = new Date(moment().month('April').startOf('month').format('YYYY-MM-DD'));
            //var endDateOfTheYear = moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD')
        } else {
            var startDateOfTheYear = new Date(moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD'));
            //var endDateOfTheYear = moment().month('March').endOf('month').format('YYYY-MM-DD')
        }
        //var startDateOfTheYear = new Date("2021-04-01");
        var endDateOfTheYear = new Date(moment().format('YYYY-MM-DD'));
        //console.log('form date', startDateOfTheYear);

        await this.setState({ fromDate: startDateOfTheYear, toDate: endDateOfTheYear }, () => {
            console.log(this.state.fromDate, this.state.toDate, "787878787");

        });
        //console.log(localStorage.getItem('type'));
        if (localStorage.getItem('type') == 'Member') {
            var searchData = [];
            searchData.push({ 'formDate': this.state.fromDate, 'toDate': this.state.toDate, 'leaderId': localStorage.getItem('userID'), 'status': this.state.status });
            //console.log(searchData);
            this.getProjectsFilter(searchData).then(data => {
                //console.log("projects", data.data.data)
                if (data.data) {
                    this.setState({ projects: data.data.data });
                    if (this.state.projects && this.state.projects.length) {
                        this.state.projects.forEach((item) => {
                            item.soCloser = !!(item.completed_on) ? this.projectOpenSinceCal('closed', item.SO_Created, item.completed_on) : this.projectOpenSinceCal('ongoing', item.SO_Created, '');
                        });
                    }
                    this.footerTotalCalculation(data.data);
                }


                this.setState({ isLoding: false });
            });
        } else {
            var searchData = [];
            searchData.push({ 'formDate': this.state.fromDate, 'toDate': this.state.toDate, 'leaderId': this.state.leaderId, 'status': this.state.status });
            //console.log(searchData);
            this.getProjectsFilter(searchData).then(data => {
                //console.log("projects", data.data.data)
                this.setState({ projects: data.data ? data.data.data : {} });
                if (this.state.projects && this.state.projects.length) {
                    this.state.projects.forEach((item) => {
                        item.soCloser = !!(item.completed_on) ? this.projectOpenSinceCal('closed', item.SO_Created, item.completed_on) : this.projectOpenSinceCal('ongoing', item.SO_Created, '');
                    });
                }
                this.footerTotalCalculation(data.data);
                this.setState({ isLoding: false });
            });
        }
    }

    footerTotalCalculation = (data) => {
        if (data == {} || data.data == {} || !data.data || !data) {
            this.setState({
                budgetdeviationCount: 0,
                totalActualMan: 0,
                totalActualdev: 0,
                totalAssiTicket: 0,
                totalCloseTicket: 0,
                totalPendingTicket: 0,
                totalPendingTicketMan: 0,
                totalActualBudget: 0,
                budgetdeviation: 0,
                totalBudget: 0,
                totalEstMan: 0,
                totalPeriodEstMan: 0,
            });
            return false;
        }
        console.log('data', data);
        return new Promise(resolve => {
            let budgetSum = 0;
            let EstManSum = 0;
            let PeriodEstManSum = 0;
            let actualManSum = 0;
            let actualdevSum = 0;
            let assiticketSum = 0;
            let closeticketSum = 0;
            let pendingticketSum = 0;
            let pendingmanSum = 0;
            let actualBudget = 0;
            let budgetDeviationSum = 0;
            let budgetdeviationCountsum = 0;

            let expenditure = global.config.api.expenditure;

            data.data.forEach((element) => {
                if (element.budget != '0.00' && element.budget != null) {
                    budgetSum += parseFloat(element.budget);
                }
                !!(element.project_estimated_man_days) ? EstManSum += parseFloat(element.project_estimated_man_days) : EstManSum += 0;
                !!(element.period_estimated_man_day) ? PeriodEstManSum += parseFloat(element.period_estimated_man_day) : PeriodEstManSum += 0;
                !!(element.actual_man_days) ? actualManSum += parseFloat(element.actual_man_days) : actualManSum += 0;
                if (element.deviation_in_man_day > 0) {
                    actualdevSum += parseFloat(element.deviation_in_man_day);
                }
                if (element.total_assign_tickets != 'N/A') {
                    assiticketSum += parseFloat(element.total_assign_tickets);
                }

                if (element.total_close_tickets != 'N/A') {
                    closeticketSum += parseFloat(element.total_close_tickets);
                }

                if (element.total_pending_tickets != 'N/A' && parseFloat(element.total_pending_tickets) > 0) {
                    pendingticketSum += parseFloat(element.total_pending_tickets);
                }

                if (element.pending_tickets_man_day != 'N/A' && parseFloat(element.pending_tickets_man_day) > 0) {
                    pendingmanSum += parseFloat(element.pending_tickets_man_day);
                }

                if (element.actual_budget != 'N/A' && element.actual_budget != null) {
                    actualBudget += parseFloat(element.actual_budget);
                }
                if (element.deviation_in_percentage != '0' && element.deviation_in_percentage != undefined) {
                    budgetDeviationSum += parseFloat(element.deviation_in_percentage.toFixed(2));
                    budgetdeviationCountsum += 1;
                }

                this.setState({
                    budgetdeviationCount: budgetdeviationCountsum,
                    totalActualMan: actualManSum,
                    totalActualdev: actualdevSum,
                    totalAssiTicket: assiticketSum,
                    totalCloseTicket: closeticketSum,
                    totalPendingTicket: pendingticketSum,
                    totalPendingTicketMan: pendingmanSum,
                    totalActualBudget: actualBudget,
                    budgetdeviation: (budgetdeviationCountsum != 0 && budgetDeviationSum != 0) ? budgetDeviationSum / budgetdeviationCountsum : 0,
                    totalBudget: budgetSum,
                    totalEstMan: EstManSum,
                    totalPeriodEstMan: PeriodEstManSum,
                });
            });
            resolve({ success: true })
        })

    }

    addDaysDate = (dateString, days) => {
        let rtn = new Date(dateString);
        var count = 0;
        while (count < days) {
            rtn.setDate(rtn.getDate() + 1);
            if (rtn.getDay() != 0 && rtn.getDay() != 6) // Skip weekends
                count++;
        }
        rtn = moment(rtn).format('DD-MM-YYYY');
        return rtn;
    }

    actualEndDataCal = (completedOn) => {
        let rtnval = '';
        if (completedOn != null) {
            rtnval = moment(completedOn).format('DD-MM-YYYY');
        } else {
            rtnval = 'N/A';
        }
        return rtnval;
    }

    dateDeviationCal = (actualEndDate, dateString, days) => {
        let estimatedMEndDate = new Date(dateString);
        let rtnval = '';
        var count = 0;
        while (count < days) {
            estimatedMEndDate.setDate(estimatedMEndDate.getDate() + 1);
            if (estimatedMEndDate.getDay() != 0 && estimatedMEndDate.getDay() != 6) // Skip weekends
                count++;
        }

        if (!!(actualEndDate)) {
            var now = moment(actualEndDate); //todays date
            var end = moment(estimatedMEndDate); // another date
            var duration = moment.duration(now.diff(end));
            var days = duration.asDays();
            rtnval = Math.floor(days);
        } else {
            rtnval = 'N/A';
        }
        return rtnval;

    }

    projectOpenSinceCal = (status, createdDate, endDate) => {
        let soCloser = 0
        if (endDate !== '') {
            let end_date = moment(endDate), start_date = moment(createdDate)
            let duration = moment.duration(end_date.diff(start_date))
            let days = duration.asDays()
            soCloser = Math.floor(days)
        }
        else {
            let end_date = moment(new Date()), start_date = moment(createdDate)
            let duration = moment.duration(end_date.diff(start_date))
            let days = duration.asDays()
            soCloser = Math.floor(days)
        }

        return soCloser
        ///////////////////////////commented by ayan //////////////////////////////
        // var rtnval = 0;
        // if (status == 'closed') {
        //     var now = moment(endDate); //todays date
        //     var end = moment(createdDate); // another date
        //     var duration = moment.duration(now.diff(end));
        //     var days = duration.asDays();
        //     rtnval = Math.floor(days);
        // } else {
        //     if (!createdDate) {
        //         rtnval = 0;
        //     } else {
        //         var now = moment(new Date()); //todays date
        //         var end = moment(createdDate); // another date
        //         var duration = moment.duration(now.diff(end));
        //         var days = duration.asDays();
        //         rtnval = Math.floor(days);
        //     }

        // }
        // return rtnval;
    }

    showBudget = () => {
        if (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') {
            if (this.state.budgetShow == false) {
                $('.budget_hide').show();
                this.setState({ budgetShow: true })

                $('.budget_button').html('Hide Budget');
            } else {
                $('.budget_hide').hide();
                this.setState({ budgetShow: false })
                //this.state.budgetShow=false;

                $('.budget_button').html('Show Budget');
            }
        } else {
            $('.budget_hide').hide();
            this.setState({ budgetShow: false })

        }


    }

    submitFilterSearch = () => {
        this.setState({ isLoding: true });
        var searchData = [];
        //console.log(leaderId);
        if (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') {
            searchData.push({ 'formDate': this.state.fromDate, 'toDate': this.state.toDate, 'leaderId': this.state.leaderId, 'status': this.state.status, 'projectType': this.state.projectType });
        } else {
            searchData.push({ 'formDate': this.state.fromDate, 'toDate': this.state.toDate, 'leaderId': localStorage.getItem('userID'), 'status': this.state.status, 'projectType': this.state.projectType });
        }
        /* if (localStorage.getItem('type') != 'Owner') {
            searchData.push({ 'formDate': this.state.fromDate, 'toDate': this.state.toDate, 'leaderId': localStorage.getItem('userID'), 'status': this.state.status,'projectType':this.state.projectType });
        } else {
            searchData.push({ 'formDate': this.state.fromDate, 'toDate': this.state.toDate, 'leaderId': this.state.leaderId, 'status': this.state.status, 'projectType': this.state.projectType });
        } */
        this.getProjectsFilter(searchData).then(data => {
            //console.log("projects", data.data.data)
            if (data) {
                this.setState({ projects: data.data.data });
            }

            if (this.state.projects !== undefined && this.state.projects.length) {
                this.state.projects.forEach((item) => {
                    item.soCloser = !!(item.completed_on) ? this.projectOpenSinceCal('closed', item.SO_Created, item.completed_on) : this.projectOpenSinceCal('ongoing', item.SO_Created, '');
                    // item.soCloser = !!(item.completed_on) ? this.projectOpenSinceCal('closed', item.actual_start_date, item.completed_on) : this.projectOpenSinceCal('ongoing', item.actual_start_date, ''); // commented by ayan
                });
            }
            this.footerTotalCalculation(data ? data.data ? data.data : {} : {});
            this.setState({ isLoding: false });
        });


    }

    CsvDownload = () => {
        const { projects } = this.state;
        let csvArray = [];
        if (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') {
            var headers = ["Project", "Status", "Leader", "Burn Down Rate", "SO-Closure", "Estimated Man Days", "Period Man Days", "Actual Man Days", "Deviation in Man Days", "Pending Ticket Man Days", "Budget", "Actual Budget", "Percentage of Deviation", "Estimated End", "Actual Start Date", "Actual End Date", "Date Deviation", "Total Assign Tickets", "Total Close Tickets", "Total Pending Tickets"];
        } else {
            var headers = ["Project", "Status", "Leader", "Burn Down Rate", "SO-Closure", "Estimated Man Days", "Period Man Days", "Actual Man Days", "Deviation in Man Days", "Pending Ticket Man Days", "Estimated End", "Actual Start Date", "Actual End Date", "Date Deviation", "Total Assign Tickets", "Total Close Tickets", "Total Pending Tickets"];
        }
        //csvArray=csvArray.bind(headers);
        csvArray.push(headers);
        if (projects.length) {
            projects.forEach((item) => {
                var proStatus = !!(item.actual_start_date) ?
                    !!(item.completed_on) ? 'Completed' : 'Ongoing'
                    : 'Not yet started';
                if (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') {
                    var temArr = [item.project_name, proStatus, item.leader_name, item.actual_man_day_per_tickets, (item.completed_on) ? '' : item.soCloser, item.project_estimated_man_days, item.period_estimated_man_day, item.actual_man_days, item.deviation_in_man_day, item.pending_tickets_estimated_man_day, item.budget, item.actual_budget, item.deviation_in_percentage, moment(item.estimated_end_date).format('DD-MM-YYYY'), moment(item.actual_start_date).format('DD-MM-YYYY'), moment(item.actual_end_date).format('DD-MM-YYYY'), item.date_deviation, item.total_assign_tickets, item.total_close_tickets, item.total_pending_tickets];
                } else {
                    var temArr = [item.project_name, proStatus, item.leader_name, item.actual_man_day_per_tickets, (item.completed_on) ? '' : item.soCloser, item.project_estimated_man_days, item.period_estimated_man_day, item.actual_man_days, item.deviation_in_man_day, item.pending_tickets_estimated_man_day, moment(item.estimated_end_date).format('DD-MM-YYYY'), moment(item.actual_start_date).format('DD-MM-YYYY'), moment(item.actual_end_date).format('DD-MM-YYYY'), item.date_deviation, item.total_assign_tickets, item.total_close_tickets, item.total_pending_tickets];
                }

                csvArray.push(temArr)

            });

        }
        //this.footerTotalCalculation(projects);
        if (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') {
            var footerTmp = ['Total', '', '', '', '', this.state.totalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalPeriodEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalPendingTicketMan.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalBudget.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalActualBudget.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.budgetdeviation.toLocaleString(undefined, { maximumFractionDigits: 2 }), '', '', '', '', this.state.totalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })];
        } else {
            var footerTmp = ['Total', '', '', '', '', this.state.totalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalPeriodEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalPendingTicketMan.toLocaleString(undefined, { maximumFractionDigits: 2 }), '', '', '', '', this.state.totalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }), this.state.totalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })];
        }

        csvArray.push(footerTmp);
        this.setState({ csvData: csvArray });
    }

    periodDropChange = async (event) => {

        if (event.target.value == 7) {
            await this.setState({ dateFilterShow: true, period: event.target.value });
            $('.show_date_filter').show();
        }
        else {
            await this.setState({ dateFilterShow: false, period: event.target.value });
            $('.show_date_filter').hide();
        }
        if (event.target.value == 0) {
            await this.setState({ fromDate: new Date('2016-08-03'), toDate: new Date(moment().format('YYYY-MM-DD')) });
        } else {
            if (event.target.value == 1) {
                var startDateOfTheYear = new Date(moment().startOf('month').format('YYYY-MM-DD'));
                var endDateOfTheYear = new Date(moment().endOf('month').format('YYYY-MM-DD'));
                //console.log('form date', startDateOfTheYear);
                //await this.setState({ fromDate: startDateOfTheYear, toDate: endDateOfTheYear });
            } else if (event.target.value == 2) {
                var startDateOfTheYear = new Date(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
                var endDateOfTheYear = new Date(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
                //console.log('form date', startDateOfTheYear);
                //await this.setState({ fromDate: startDateOfTheYear, toDate: endDateOfTheYear });
            } else if (event.target.value == 3) {

                var startDateOfTheYear = new Date(moment().quarter(moment().quarter()).startOf('quarter').format('YYYY-MM-DD'));
                var endDateOfTheYear = new Date(moment().quarter(moment().quarter()).endOf('quarter').format('YYYY-MM-DD'));
                //console.log('form date', startDateOfTheYear);
                //await this.setState({ fromDate: startDateOfTheYear, toDate: endDateOfTheYear });
            } else if (event.target.value == 4) {

                var startDateOfTheYear = new Date(moment().quarter(moment().quarter()).subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
                var endDateOfTheYear = new Date(moment().quarter(moment().quarter()).subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));
                //console.log('form date', startDateOfTheYear);
                //await this.setState({ fromDate: startDateOfTheYear, toDate: endDateOfTheYear });
            } else if (event.target.value == 5) {

                if (moment().quarter() === 2) {
                    var startDateOfTheYear = new Date(moment().month('April').startOf('month').format('YYYY-MM-DD'))
                    var endDateOfTheYear = new Date(moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD'))
                } else {
                    var startDateOfTheYear = new Date(moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD'))
                    var endDateOfTheYear = new Date(moment().month('March').endOf('month').format('YYYY-MM-DD'))
                }

            } else if (event.target.value == 6) {

                if (moment().quarter() === 2) {
                    var thisFiscalStart = moment().month('April').startOf('month').format('YYYY-MM-DD')
                    var thisFiscalEnd = moment().add(1, 'year').month('March').endOf('month').format('YYYY-MM-DD')
                } else {
                    var thisFiscalStart = moment().subtract(1, 'year').month('April').startOf('month').format('YYYY-MM-DD')
                    var thisFiscalEnd = moment().month('March').endOf('month').format('YYYY-MM-DD')
                }

                var startDateOfTheYear = new Date(moment(thisFiscalStart).subtract(1, 'year').format('YYYY-MM-DD'))
                var endDateOfTheYear = new Date(moment(thisFiscalEnd).subtract(1, 'year').format('YYYY-MM-DD'))
            }
            var currentDate = new Date(moment().format('YYYY-MM-DD'));
            if (endDateOfTheYear > currentDate) {
                endDateOfTheYear = currentDate;
            }
            await this.setState({ fromDate: startDateOfTheYear, toDate: endDateOfTheYear });
        }


        $('.detail_hide').find('#period').val(event.target.value);
        //$('.detail_hide').find('#period').val(event.target.value);

    }

    dataTableSort = (sort) => {
        let projects = this.state.projects
        if (sort == 'so') {
            let so_rev = !this.state.so_rev;
            if (so_rev) {
                projects.sort((a, b) => b.soCloser - a.soCloser);

            } else {
                projects.sort((a, b) => a.soCloser - b.soCloser);
            }
            this.setState({ so_rev });

        } else if (sort == 'budget') {
            let budget_rev = !this.state.budget_rev;
            if (budget_rev) {
                projects.sort((a, b) => b.budget - a.budget);

            } else {
                projects.sort((a, b) => a.budget - b.budget);
            }
            this.setState({ budget_rev });


        } else if (sort == 'actual') {
            let actual_rev = !this.state.actual_rev;
            if (actual_rev) {
                projects.sort((a, b) => b.actual_budget - a.actual_budget);

            } else {
                projects.sort((a, b) => a.actual_budget - b.actual_budget);
            }
            this.setState({ actual_rev });

        }
        this.setState({ projects })
    }

    highliterRemove = () => {
        var els = document.querySelectorAll('.highlight');
        for (var i = 0; i < els.length; i++) {
            els[i].classList.remove('highlight')

        }
        // document.getElementsByClassName('highlight').remove("highlight");
    }

    searchProject = (event) => {
        //console.log(event.keyCode)

        if (event.keyCode === 13) {
            this.highliterRemove();
            //console.log('hello');
            var searchWord = event.target.value;
            var searchHtml = document.getElementById("example");
            searchHtml.getElementsByClassName('highlight').className = "not-highlight";
            var searchedPara = searchHtml.innerHTML;
            var index = searchedPara.toLowerCase().indexOf(searchWord);
            if (index >= 0) {
                searchedPara = searchedPara.substring(0, index) + "<span class='highlight'>" + searchedPara.substring(index, index + searchWord.length) + "</span>" + searchedPara.substring(index + searchWord.length);
                searchHtml.innerHTML = searchedPara;
            }
        }
    }

    getDetailFilter = (filterData) => {
        return new Promise((resolve, reject) => {
            fetch(global.config.api.remotePath + "user/resource-performance-details", {
                method: 'post',
                body: JSON.stringify(filterData),
                headers: {
                    'content-type': 'application/json'
                }
            }).then((res) => res.json())
                .then(data => {
                    if (data) {
                        resolve({ success: true, data: data })
                    } else {
                        resolve({ success: false, data: [] })
                    }
                }).catch(err => {
                    resolve({ success: false, err: err })
                })
        });
    }

    mappedApiDatWithCalculationDetail = (data) => {
        if (data) {
            this.setState({ detailPerformance: data.data });
        }

    }

    detailsFooterTotalCalculation = (data) => {
        if (data == {} || data.data == {} || !data.data || !data) {
            this.setState({
                dbudgetdeviationCount: 0,
                dtotalActualMan: 0,
                dtotalActualdev: 0,
                dtotalAssiTicket: 0,
                dtotalCloseTicket: 0,
                dtotalPendingTicket: 0,
                dtotalPendingTicketMan: 0,
                dtotalActualBudget: 0,
                dbudgetdeviation: 0,
                dtotalBudget: 0,
                dtotalEstMan: 0,
            });
            return false;
        }
        return new Promise(resolve => {
            let budgetSum = 0;
            let EstManSum = 0;
            let actualManSum = 0;
            let actualdevSum = 0;
            let assiticketSum = 0;
            let closeticketSum = 0;
            let pendingticketSum = 0;
            let pendingmanSum = 0;
            let actualBudget = 0;
            let budgetDeviationSum = 0;
            let budgetdeviationCountsum = 0;

            let expenditure = global.config.api.expenditure;

            data.data.forEach((element) => {
                //console.log('dataelement',element);
                if (element.estimated_budget != '0.00' && element.estimated_budget != null) {
                    budgetSum += parseFloat(element.estimated_budget);
                }
                !!(element.estimated_man_day) ? EstManSum += parseFloat(element.estimated_man_day) : EstManSum += 0;
                !!(element.actual_man_day) ? actualManSum += parseFloat(element.actual_man_day) : actualManSum += 0;
                if (element.deviation_in_man_day != 'N/A') {
                    actualdevSum += parseFloat(element.deviation_in_man_day);
                }
                if (element.total_tickets != 'N/A') {
                    assiticketSum += parseFloat(element.total_tickets);
                }

                if (element.close_tickets != 'N/A') {
                    closeticketSum += parseFloat(element.close_tickets);
                }

                if (element.pending_tickets != 'N/A' && parseFloat(element.pending_tickets) > 0) {
                    pendingticketSum += parseFloat(element.pending_tickets);
                }

                if (element.pending_tickets_estimated_man_day != 'N/A' && parseFloat(element.pending_tickets_estimated_man_day) > 0) {
                    pendingmanSum += parseFloat(element.pending_tickets_estimated_man_day);
                }

                if (element.actual_budget != 'N/A' && element.actual_budget != null) {
                    actualBudget += parseFloat(element.actual_budget);
                }
                if (element.deviation_in_budget != 'N/A' && element.deviation_in_budget != null) {
                    budgetDeviationSum += parseFloat(element.deviation_in_budget);
                    budgetdeviationCountsum += 1;
                }

                this.setState({
                    dbudgetdeviationCount: budgetdeviationCountsum,
                    dtotalActualMan: actualManSum,
                    dtotalActualdev: actualdevSum,
                    dtotalAssiTicket: assiticketSum,
                    dtotalCloseTicket: closeticketSum,
                    dtotalPendingTicket: pendingticketSum,
                    dtotalPendingTicketMan: pendingmanSum,
                    dotalActualBudget: actualBudget,
                    dbudgetdeviation: (budgetdeviationCountsum != 0 && budgetDeviationSum != 0) ? budgetDeviationSum / budgetdeviationCountsum : 0,
                    dtotalBudget: budgetSum,
                    dtotalActualBudget: actualBudget,
                    dtotalEstMan: EstManSum,
                });
            });
            resolve({ success: true })
        });
    }

    detailFilterSubmit = async () => {
        this.setState({ isLoding: true });
        var searchData = [];
        console.log(this.state.year);

        searchData.push({ 'projectId': this.state.projectId, 'fromDate': moment(this.state.fromDate).format('YYYY-MM-DD'), 'toDate': moment(this.state.toDate).format('YYYY-MM-DD') });
        ///console.log(searchData);
        await this.getDetailFilter(searchData).then(data => {
            //console.log('API data',data.data);
            this.mappedApiDatWithCalculationDetail(data ? data.data ? data.data : {} : {});
            this.detailsFooterTotalCalculation(data ? data.data ? data.data : {} : {});
        });
        this.setState({ isLoding: false });
    }

    showdetail = async (projectId, projectName) => {
        var searchData = [];
        this.setState({ isLoding: true });
        this.setState({ modalTitle: "Performance details of " + projectName });
        this.setState({ projectId: projectId });
        //console.log('from_date',this.state.fromDate);
        if (this.state.fromDate) {
            searchData.push({ 'projectId': projectId, 'fromDate': moment(this.state.fromDate).format('YYYY-MM-DD'), 'toDate': moment(this.state.toDate).format('YYYY-MM-DD') });
        } else {
            searchData.push({ 'projectId': projectId });
        }
        console.log(searchData);

        await this.getDetailFilter(searchData).then(data => {
            //console.log('API data',data.data);
            this.mappedApiDatWithCalculationDetail(data ? data.data ? data.data : {} : {});
            this.detailsFooterTotalCalculation(data ? data.data ? data.data : {} : {});
        });

        this.setState({ isLoding: false });

        $('.detail_hide').show();
        //$('.budget_hide').find('.modal-body').html(html);
    }

    showDetailClose = () => {
        $('.detail_hide').hide();
    }

    DetailsCsvDownload = () => {
        const { detailPerformance } = this.state;
        let csvArray = [];

        var headers = ["Resource", "Leader", "Estimated Man Days", "Actual Man Days", "Deviation", "Pending", "Estimated Budget", "Actual Budget", "Deviation", "Assign Tickets", "Close Tickets", "Pending Tickets"];
        //csvArray=csvArray.bind(headers);

        csvArray.push(headers);
        if (detailPerformance.length) {
            detailPerformance.forEach((item) => {


                var temArr = [item.resource_name, item.leader_name, item.estimated_man_day, item.actual_man_day, item.deviation_in_man_day, item.pending_tickets_estimated_man_day, item.estimated_budget, item.actual_budget, item.deviation_in_budget, item.total_tickets, item.close_tickets, item.pending_tickets];
                csvArray.push(temArr);
            });

        }
        //this.footerTotalCalculation(projects);

        var footerTmp = ['Total', ' ',
            this.state.dtotalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            this.state.dtotalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            this.state.dtotalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            this.state.dtotalPendingTicketMan.toLocaleString(undefined, { maximumFractionDigits: 2 }),

            this.state.dtotalBudget.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            this.state.dtotalActualBudget.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            this.state.dbudgetdeviation.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            this.state.dtotalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            this.state.dtotalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 }),
            this.state.dtotalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })]
        csvArray.push(footerTmp);
        this.setState({ csvDataDetails: csvArray });
    }

    detailsDataTableSort = (sort) => {
        let detailPerformance = this.state.detailPerformance
        if (sort == 'estman') {
            let est2_rev = !this.state.est2_rev;
            if (est2_rev) {
                detailPerformance.sort((a, b) => b.estimated_man_day - a.estimated_man_day);

            } else {
                detailPerformance.sort((a, b) => a.estimated_man_day - b.estimated_man_day);
            }
            this.setState({ est2_rev });

        } else if (sort == 'actualman') {
            let actual2_rev = !this.state.actual2_rev;
            if (actual2_rev) {
                detailPerformance.sort((a, b) => b.actual_man_days - a.actual_man_days);

            } else {
                detailPerformance.sort((a, b) => a.actual_man_days - b.actual_man_days);
            }
            this.setState({ actual2_rev });


        } else if (sort == 'estbudget') {
            let estb2_rev = !this.state.estb2_rev;
            if (estb2_rev) {
                detailPerformance.sort((a, b) => b.estimated_budget - a.estimated_budget);

            } else {
                detailPerformance.sort((a, b) => a.estimated_budget - b.estimated_budget);
            }
            this.setState({ estb2_rev });


        } else if (sort == 'actualbudget') {
            let actualb2_rev = !this.state.actualb2_rev;
            if (actualb2_rev) {
                detailPerformance.sort((a, b) => b.actual_budget - a.actual_budget);

            } else {
                detailPerformance.sort((a, b) => a.actual_budget - b.actual_budget);
            }
            this.setState({ actualb2_rev });
        } else if (sort == 'working') {
            let working_rev = !this.state.working_rev;
            if (working_rev) {
                detailPerformance.sort((a, b) => b.working_since - a.working_since);

            } else {
                detailPerformance.sort((a, b) => a.working_since - b.working_since);
            }
            this.setState({ working_rev });
        }

        this.setState({ detailPerformance })
    }

    formatDate = (dt) => {
        const date = new Date(dt)
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day}${month}, ${year}`;
    }

    render = () => {
        let columns = [
            {
                name: 'actual_budget',
                selector: 'actual_budget',
                sortable: true,
            },
            {
                name: 'actual_man_days',
                selector: 'actual_man_days',
                sortable: true,
            },
        ]
        console.log(this.state.projects, "565656565");

        return (
            <div>

                <section id="contents">
                    <Header />
                    <section className="statistics project-tab">
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account' || localStorage.getItem('type') == 'Member') ? (
                                        <div className="col-md-2 width-auto2 active">
                                            <div className="box" style={{ backgroundColor: '#42ca37' }}>
                                                <a href="/project-logs">
                                                    <div className="info">
                                                        <h3>Project Performance</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'HR') ? (
                                        <div className="col-md-2 width-auto2">
                                            <div className="box">
                                                <a href="/performance">
                                                    <div className="info">
                                                        <h3>Resource Performance</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                                {
                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'HR') ? (
                                        <div className="col-md-2 width-auto2">
                                            <div className="box">
                                                <a href="/wbs-upload">
                                                    <div className="info">
                                                        <h3>WBS Upload</h3>
                                                        <i className="fa fa-star" aria-hidden="true"></i>
                                                    </div></a>
                                            </div>
                                        </div>
                                    ) : ''
                                }

                            </div>
                        </div>
                    </section>
                    <section className="statistics">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="col-md-12">
                                            <div className="section2">
                                                <div className="row">

                                                    <div className="col-md-2 width-auto">
                                                        <div className="form-group">
                                                            <label>Period </label>
                                                            <select className="form-control form-control-sm" name="period" id="period" onChange={this.periodDropChange} >
                                                                <option>Select</option>
                                                                <option value="0">All</option>
                                                                <option value="1">Current Month</option>
                                                                <option value="2">Previous Month</option>
                                                                <option value="3">Current Qtr</option>
                                                                <option value="4">Previous Qtr</option>
                                                                <option value="5" selected>Current FY</option>
                                                                <option value="6">Previous FY</option>
                                                                <option value="7">Custom</option>
                                                            </select>
                                                        </div>


                                                    </div>
                                                    <div className="show_date_filter" style={{ display: "none" }}>
                                                        <div className='col-md-3 width-auto'>
                                                            <div className="form-group">
                                                                <label>From Date</label>
                                                                <DatePicker selected={this.state.fromDate} onChange={date => this.setState({ fromDate: date })} dateFormat="yyyy-mm-dd" className="form-control form-control-sm form_date" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3 width-auto'>
                                                            <div className="form-group">
                                                                <label>To Date</label>
                                                                <DatePicker selected={this.state.toDate} onChange={date => this.setState({ toDate: date })} dateFormat="yyyy-mm-dd" className="form-control form-control-sm to_date" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2 width-auto'>
                                                        <div className="form-group">
                                                            <label>Type</label>
                                                            <select className="form-control form-control-sm" name="project_type" id="project_type" onChange={(event) => this.setState({ projectType: event.target.value })}>
                                                                <option value="">--- Select ---</option>
                                                                <option value="">All</option>
                                                                <option value="1">AMC</option>
                                                                <option value="1">Service</option>
                                                                <option value="1">Product</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {
                                                        (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                            <div className='col-md-2 width-auto'>
                                                                <div className="form-group">
                                                                    <label>Leader</label>
                                                                    <select className="form-control form-control-sm" name="leader_id" id="leader_id" onChange={(event) => this.setState({ leaderId: event.target.value })}>
                                                                        <option value="">Select leader</option>
                                                                        <option value="">All</option>
                                                                        {
                                                                            (this.state.leaderDrops.length) ? (
                                                                                this.state.leaderDrops.map((ele, i) => (
                                                                                    <option key={i} value={ele.dis_project_lead_id}>{ele.leader_name}</option>
                                                                                ))
                                                                            ) : (
                                                                                <option>No Leader found!</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    }

                                                    <div className='col-md-2 width-auto'>
                                                        <div className="form-group">
                                                            <label>Status</label>
                                                            <select className="form-control form-control-sm" name="status" id="status" onChange={(event) => this.setState({ status: event.target.value })}>
                                                                <option value="">Select Status</option>
                                                                <option value="">All</option>
                                                                <option value="1">Ongoing</option>
                                                                <option value="2">Closed</option>
                                                                <option value="3">Not started</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-2 width-auto'>
                                                        <div className="form-group" >
                                                            <button onClick={() => this.submitFilterSearch()} className="btn btn-info btn-sm filter-btn">Filter</button>
                                                            <a href="/project-logs" className="btn btn-sm btn-warning">Clear</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="search_div pull-right">
                                                <div className="col-md-2 width-auto">
                                                    <div className="form-group">

                                                        <input type="text" name="project_name" class="form-control" placeholder="Search project" onKeyUp={this.searchProject} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="statistics">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box">
                                        <div className="colorindex">
                                            <div className="col-md-6">
                                                <div className="led-box">
                                                    <div className="led-blue"></div>

                                                    <p className="led-text">Ongoing</p></div>
                                                <div className="led-box">
                                                    <div className="led-green"></div>

                                                    <p className="led-text">Completed</p></div>
                                                <div className="led-box">
                                                    <div className="led-red"></div>

                                                    <p className="led-text">Not yet started</p>
                                                </div>
                                            </div>


                                            {
                                                (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                    <div className="right-box">
                                                        <button onClick={() => this.showBudget()} className="btn btn-sm btn-info budget_button">Hide Budget</button>
                                                        <CSVLink data={this.state.csvData} onClick={() => this.CsvDownload()} filename={"ProjectLogs.csv"} target="_blank" className="btn btn-sm btn-success pull-right">Export</CSVLink>
                                                    </div>
                                                ) : ''
                                            }
                                        </div>

                                        <div className="table-box">

                                            <table className="table table-sm table-responsive table-bordered project_table" id="example">
                                                <thead className="project_table_th">
                                                    <tr className="main_th">
                                                        <th scope="col" colSpan="4" className="project-header">Project  & Leader</th>
                                                        <th scope="col" colSpan="6" className="mandays-header">Project Man Days</th>
                                                        {
                                                            (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                                <th scope="col" colSpan="3" className="budget-header budget_hide">Budget</th>
                                                            ) : ''
                                                        }

                                                        <th scope="col" colSpan="4" className="status-header">Dates</th>
                                                        <th scope="col" colSpan="3" className="ticket-header">Total Tickets</th>
                                                        {/* <th scope="col" colSpan="4" className="projection-header">Projection</th> */}
                                                    </tr>
                                                    <tr className="header-th2">
                                                        <th scope="col" className="project-header">Name</th>
                                                        <th scope="col" className="project-header">Leader</th>
                                                        <th scope="col" className="project-header">Proposed Burn Down Rate</th>
                                                        <th scope="col" className="project-header">Actual Burn Down Rate</th>

                                                        {/* for project mandays */}
                                                        <th scope="col" className="mandays-header">SO-Closure <a href="#" onClick={() => this.dataTableSort('so')} className="soCloserSort">
                                                            {
                                                                !(this.state.so_rev) ? (
                                                                    <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                ) : (
                                                                    <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                )
                                                            }
                                                        </a></th>
                                                        <th scope="col" className="mandays-header">Project Est.</th>
                                                        <th scope="col" className="mandays-header">Period Est.</th>
                                                        <th scope="col" className="mandays-header">Actual</th>
                                                        <th scope="col" className="mandays-header">Deviation</th>
                                                        <th scope="col" className="mandays-header">Pending</th>

                                                        {
                                                            (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                                <>
                                                                    <th scope="col" className="budget-header budget_hide">Budget
                                                                        <a href="#" onClick={() => this.dataTableSort('budget')} className="budgetSort">
                                                                            {
                                                                                !(this.state.budget_rev) ? (
                                                                                    <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                                ) : (
                                                                                    <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                                )
                                                                            }
                                                                        </a> </th>
                                                                    <th scope="col" className="budget-header budget_hide">Actual
                                                                        <a href="#" onClick={() => this.dataTableSort('actual')} className="actualBudgetSort">{
                                                                            !(this.state.actual_rev) ? (
                                                                                <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                                            ) : (
                                                                                <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                                            )
                                                                        }</a></th>
                                                                    <th scope="col" className="budget-header budget_hide">% of Deviation</th>
                                                                </>

                                                            ) : ''
                                                        }




                                                        <th scope="col" className="status-header">Estimated End</th>
                                                        <th scope="col" className="status-header">Actual Start</th>
                                                        <th scope="col" className="status-header">Actual End</th>
                                                        <th scope="col" className="status-header">Devi</th>

                                                        <th scope="col" className="ticket-header"> Assign</th>
                                                        <th scope="col" className="ticket-header">Close</th>
                                                        <th scope="col" className="ticket-header">Pending</th>
                                                        {/* <th scope="col" className="projection-header ">Man Days</th>
                                                <th scope="col" className="projection-header">End Date</th>
                                                <th scope="col" className="projection-header">Deviation</th>
                                                <th scope="col" className="projection-header">Profit</th> */}

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (this.state.isLoding) ? (<Loader
                                                            type="TailSpin"
                                                            color="#00BFFF"
                                                            height={100}
                                                            width={100}
                                                            style={{ justifyContent: 'center', alignItems: 'center' }}
                                                        />) : (this.state.projects !== undefined && this.state.projects.length) ? (
                                                            this.state.projects.map((item, i) => (
                                                                <tr key={i}>
                                                                    <td className="project-header">
                                                                        {
                                                                            !!(item.actual_start_date) ?
                                                                                !!(item.completed_on) ? (
                                                                                    <div className="led-box">
                                                                                        <div className="led-green"></div>
                                                                                    </div>
                                                                                ) : (<div className="led-box">
                                                                                    <div className="led-blue"></div>
                                                                                </div>)
                                                                                : (
                                                                                    <div className="led-box">
                                                                                        <div className="led-red"></div>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                        <p className="led-text"><a href="#" onClick={() => this.showdetail(item.project_id, item.project_name)} >{item.project_name}</a></p>
                                                                    </td>
                                                                    <td className="project-header">{item.leader_name}</td>
                                                                    <td className="project-header">{item.estimated_man_day_per_tickets}</td>
                                                                    <td className="project-header">{item.actual_man_day_per_tickets}</td>
                                                                    {
                                                                        (item.completed_on) ? (
                                                                            <td className="mandays-header"></td>
                                                                        ) : (
                                                                            <td className="mandays-header">{item.soCloser}</td>
                                                                        )
                                                                    }

                                                                    <td className="mandays-header" style={{ textAlign: 'right' }}>{item.project_estimated_man_days || 0}</td>
                                                                    <td className="mandays-header" style={{ textAlign: 'right' }}>{item.period_estimated_man_day || 0}</td>
                                                                    <td className="mandays-header" style={{ textAlign: 'right' }}>{item.actual_man_days || 0}</td>
                                                                    <td className="mandays-header" style={{ textAlign: 'right' }}>{item.deviation_in_man_day || 0}</td>

                                                                    <td className="mandays-header" style={{ textAlign: 'right' }}>{item.pending_tickets_estimated_man_day || 0}</td>
                                                                    {/* <td>{ item.project_status }</td> */}
                                                                    {
                                                                        (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                                            <>
                                                                                <td className="budget-header budget_hide" style={{ textAlign: 'right' }}>{item.budget || 0}</td>
                                                                                <td className="budget-header budget_hide" style={{ textAlign: 'right' }}>{item.actual_budget || 0} </td>
                                                                                <td className="mandays-header" style={{ textAlign: 'right' }}>{item.deviation_in_percentage || 0}</td>
                                                                            </>

                                                                        ) : ''
                                                                    }


                                                                    <td className="status-header">
                                                                        {this.formatDate(item.estimated_end_date)}</td>
                                                                    <td className="status-header">
                                                                        {this.formatDate(item.actual_start_date)}
                                                                    </td>
                                                                    <td className="status-header">
                                                                        {this.formatDate(item.actual_end_date)}
                                                                    </td>
                                                                    <td className="status-header">{item.date_deviation}</td>

                                                                    <td className="ticket-header" style={{ textAlign: 'right' }}>{item.total_assign_tickets || 0}</td>
                                                                    <td className="ticket-header" style={{ textAlign: 'right' }}>{item.total_close_tickets || 0}</td>
                                                                    <td className="ticket-header" style={{ textAlign: 'right' }}>{item.total_pending_tickets || 0}</td>

                                                                    {/* <td className="projection-header" style={{ textAlign: 'right' }}>{'N/A'}</td>
                                                            <td className="projection-header" style={{ textAlign: 'right' }}>{'N/A'}</td>
                                                            <td className="projection-header" style={{ textAlign: 'right' }}>{'N/A'}</td>
                                                            <td className="projection-header" style={{ textAlign: 'right' }}>{'N/A'}</td> */}
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="12"> No data found!</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                                <tfoot style={{ 'font-size': '12px' }}>
                                                    <th style={{ textAlign: 'center' }}>Total</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th className="mandays-header" style={{ textAlign: 'right' }}></th>
                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalPeriodEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                    <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.totalPendingTicketMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                    {
                                                        (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                            <>
                                                                <th className="budget-header budget_hide" style={{ textAlign: 'right' }}>{this.state.totalBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                <th className="budget-header budget_hide" style={{ textAlign: 'right' }}>{this.state.totalActualBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                                <th className="budget-header budget_hide" style={{ textAlign: 'right' }}>{this.state.budgetdeviation.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                                }</th>
                                                            </>

                                                        ) : ''
                                                    }


                                                    <th className="status-header"></th>
                                                    <th className="status-header"></th>
                                                    <th className="status-header"></th>
                                                    <th className="status-header"></th>

                                                    <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                    <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                    <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.totalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>

                                                    {/* <th className="projection-header"></th>
                                            <th className="projection-header"></th>
                                            <th className="projection-header"></th>
                                            <th className="projection-header"></th> */}

                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="modal detail_hide" id="resource_detail_modal" tabindex="-1" role="dialog">
                        <div className="modal-dialog" role="document" style={{ width: "90%", height: "300px" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.state.modalTitle}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.showDetailClose()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <section className="statistics">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="box">
                                                        <div className="col-md-12">
                                                            <div className="section2">
                                                                <div className="row">
                                                                    <div className="col-md-2 width-auto">
                                                                        <div className="form-group">
                                                                            <label>Period </label>
                                                                            <select className="form-control form-control-sm" name="period" id="period" onChange={this.periodDropChange} >
                                                                                <option>Select</option>
                                                                                <option value="0">All</option>
                                                                                <option value="1">Current Month</option>
                                                                                <option value="2">Previous Month</option>
                                                                                <option value="3">Current Qtr</option>
                                                                                <option value="4">Previous Qtr</option>
                                                                                <option value="5" selected>Current FY</option>
                                                                                <option value="6">Previous FY</option>
                                                                                <option value="7">Custom</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="show_date_filter" style={{ display: 'none' }}>
                                                                        <div className='col-md-3 width-auto'>
                                                                            <div className="form-group">
                                                                                <label>From Date</label>
                                                                                <DatePicker selected={this.state.fromDate} onChange={date => this.setState({ fromDate: date })} dateFormat="yyyy-MM-dd" className="form-control form-control-sm form_date" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-3 width-auto'>
                                                                            <div className="form-group">
                                                                                <label>To Date</label>
                                                                                <DatePicker selected={this.state.toDate} onChange={date => this.setState({ toDate: date })} dateFormat="yyyy-MM-dd" className="form-control form-control-sm to_date" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-2 width-auto'>
                                                                        <div className="form-group" >
                                                                            <button onClick={() => this.detailFilterSubmit()} className="btn btn-info btn-sm filter-btn">Filter</button>
                                                                            <a href="/project-logs" className="btn btn-sm btn-warning">Clear</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <CSVLink data={this.state.csvDataDetails} onClick={() => this.DetailsCsvDownload()} filename={this.state.modalTitle + ".csv"} target="_blank" className="btn btn-sm btn-success pull-right">Export</CSVLink>
                                    <table className="table table-sm table-responsive table-bordered modal_table modal_tbl_project" id="example">
                                        <thead className="project_table_th">
                                            <tr className="main_th">
                                                <th scope="col" colSpan="2" className="project-header">Resource  & Leader</th>
                                                <th scope="col" colSpan="4" className="mandays-header">Project Man Days</th>
                                                {
                                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (<th scope="col" colSpan="3" className="budget-header budget_hide">Budget</th>) : ''
                                                }


                                                <th scope="col" colSpan="3" className="ticket-header">Total Tickets</th>
                                                {/* <th scope="col" colSpan="4" className="projection-header">Projection</th> */}
                                            </tr>
                                            <tr className="header-th2">
                                                <th scope="col" className="project-header">Resource</th>
                                                <th scope="col" className="project-header">Leader</th>


                                                <th scope="col" className="mandays-header">Estimated
                                                    <a href="#" onClick={() => this.detailsDataTableSort('estman')} className="soCloserSort">
                                                        {
                                                            !(this.state.est2_rev) ? (
                                                                <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                            ) : (
                                                                <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                            )
                                                        }
                                                    </a>
                                                </th>
                                                <th scope="col" className="mandays-header">Actual
                                                    <a href="#" onClick={() => this.detailsDataTableSort('actualman')} className="soCloserSort">
                                                        {
                                                            !(this.state.actual2_rev) ? (
                                                                <i className="fa fa-arrow-down sort_arrowa" aria-hidden="true"></i>
                                                            ) : (
                                                                <i class="fa fa-arrow-up sort_arrowa" aria-hidden="true"></i>

                                                            )
                                                        }
                                                    </a>
                                                </th>
                                                <th scope="col" className="mandays-header">Deviation</th>
                                                <th scope="col" className="mandays-header">Pending</th>
                                                {
                                                    (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                        <>
                                                            <th scope="col" className="budget-header">Estimated</th>
                                                            <th scope="col" className="budget-header">Actual</th>
                                                            <th scope="col" className="budget-header">Deviation</th>
                                                        </>
                                                    ) : ''

                                                }


                                                <th scope="col" className="ticket-header">Assign </th>
                                                <th scope="col" className="ticket-header">Close </th>
                                                <th scope="col" className="ticket-header">Pending </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (this.state.isLoding) ? (<Loader
                                                    type="TailSpin"
                                                    color="#00BFFF"
                                                    height={100}
                                                    width={100}
                                                    style={{ justifyContent: 'center', alignItems: 'center' }}
                                                />) : (this.state.detailPerformance && this.state.detailPerformance.length) ? (
                                                    this.state.detailPerformance.map((item, i) => (
                                                        <tr key={i}>
                                                            {/* <td className="project-header" >{item.leader_name}</td>
                                                        <td className="project-header">{item.project_name}</td> */}
                                                            <td className="project-header" >{item.resource_name}</td>
                                                            <td className="project-header">{item.leader_name}</td>

                                                            <td className="mandays-header">{item.estimated_man_day}</td>
                                                            <td className="mandays-header">{item.actual_man_day}</td>
                                                            <td className="mandays-header">{item.deviation_in_man_day}</td>
                                                            <td className="mandays-header">{item.pending_tickets_estimated_man_day}</td>
                                                            {
                                                                (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                                    <>
                                                                        <td className="budget-header">{item.estimated_budget}</td>
                                                                        <td className="budget-header">{item.actual_budget}</td>
                                                                        <td className="budget-header">{item.deviation_in_budget}</td>
                                                                    </>
                                                                ) : ''
                                                            }


                                                            <td className="ticket-header">{item.total_tickets}</td>
                                                            <td className="ticket-header">{item.close_tickets}</td>
                                                            <td className="ticket-header">{item.pending_tickets}</td>

                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="12"> No data found!</td>
                                                    </tr>
                                                )
                                            }


                                        </tbody>
                                        <tfoot style={{ 'font-size': '12px' }}>
                                            <th style={{ textAlign: 'center' }}>Total</th>
                                            <th></th>


                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.dtotalEstMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.dtotalActualMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.dtotalActualdev.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                            <th className="mandays-header" style={{ textAlign: 'right' }}>{this.state.dtotalPendingTicketMan.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                            {
                                                (localStorage.getItem('type') == 'Owner' || localStorage.getItem('type') == 'Account') ? (
                                                    <>
                                                        <th className="budget-header budget_hide" style={{ textAlign: 'right' }}>{this.state.dtotalBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                        <th className="budget-header budget_hide" style={{ textAlign: 'right' }}>{this.state.dtotalActualBudget.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                        <th className="budget-header budget_hide" style={{ textAlign: 'right' }}>{this.state.dbudgetdeviation.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                        }</th>
                                                    </>
                                                ) : ''
                                            }



                                            <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.dtotalAssiTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                            <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.dtotalCloseTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                            <th className="ticket-header" style={{ textAlign: 'right' }}>{this.state.dtotalPendingTicket.toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>

                                            {/* <th className="projection-header"></th>
                                            <th className="projection-header"></th>
                                            <th className="projection-header"></th>
                                            <th className="projection-header"></th> */}

                                        </tfoot>
                                    </table>
                                </div>
                                {/* <div class="modal-footer">
                                <button type="button" class="btn btn-primary">Save changes</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default ProjectLogs;