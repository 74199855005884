import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Redirect } from "react-router-dom";
import "../config";

const Logout=function (props) {
    localStorage.removeItem('userID');
    localStorage.removeItem('firstName');
    //localStorage.removeItem('lastName');
    localStorage.removeItem('email');
    //localStorage.removeItem('title');
    window.location.href = '/admin-login';
}
export default Logout;